"use client";

// hooks
import { memo, useContext } from "react";

// context
import { TungShingContext } from "@/components/tung-shing/provider";

// utils
import dayjs from "dayjs";

// comp
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from "antd";

// 生肖相冲
export const shengXiaoChong = new Map([
  [
    '鼠',
    {
      'chong': ['马'], // 相冲
      'xing': ['兔'], // 相刑
      'hai': ['羊'], // 相害
      'po': ['鸡'], // 相破
      'sanhe': ['猴', '龙'], // 三合
      'liuhe': ['牛'], // 六合
    }
  ],
  [
    '牛',
    {
      'chong': ['羊'], // 相冲
      'xing': ['狗', '羊'], // 相刑
      'hai': ['马'], // 相害
      'po': ['龙'], // 相破
      'sanhe': ['蛇', '鸡'], // 三合
      'liuhe': ['鼠'], // 六合
    }
  ],
  [
    '虎',
    {
      'chong': ['猴'], // 相冲
      'xing': ['蛇', '猴'], // 相刑
      'hai': ['蛇'], // 相害
      'po': ['猪'], // 相破
      'sanhe': ['马', '狗'], // 三合
      'liuhe': ['猪'], // 六合
    }
  ],
  [
    '兔',
    {
      'chong': ['鸡'], // 相冲
      'xing': ['鼠'], // 相刑
      'hai': ['龙'], // 相害
      'po': ['马'], // 相破
      'sanhe': ['猪', '羊'], // 三合
      'liuhe': ['狗'], // 六合
    }
  ],
  [
    '龙',
    {
      'chong': ['狗'], // 相冲
      'xing': ['龙'], // 相刑，相同就是自刑
      'hai': ['兔'], // 相害
      'po': ['牛'], // 相破
      'sanhe': ['猴', '鼠'], // 三合
      'liuhe': ['鸡'], // 六合
    }
  ],
  [
    '蛇',
    {
      'chong': ['猪'], // 相冲
      'xing': ['虎', '猴'], // 相刑
      'hai': ['虎'], // 相害
      'po': ['猴'], // 相破
      'sanhe': ['牛', '鸡'], // 三合
      'liuhe': ['猴'], // 六合
    }
  ],
  [
    '马',
    {
      'chong': ['鼠'], // 相冲
      'xing': ['马'], // 相刑，相同就是自刑
      'hai': ['牛'], // 相害
      'po': ['兔'], // 相破
      'sanhe': ['虎', '狗'], // 三合
      'liuhe': ['羊'], // 六合
    }
  ],
  [
    '羊',
    {
      'chong': ['牛'], // 相冲
      'xing': ['狗', '牛'], // 相刑
      'hai': ['鼠'], // 相害
      'po': ['狗'], // 相破
      'sanhe': ['兔', '猪'], // 三合
      'liuhe': ['马'], // 六合
    }
  ],
  [
    '猴',
    {
      'chong': ['虎'], // 相冲
      'xing': ['虎', '蛇'], // 相刑
      'hai': ['猪'], // 相害
      'po': ['蛇'], // 相破
      'sanhe': ['鼠', '龙'], // 三合
      'liuhe': ['蛇'], // 六合
    }
  ],
  [
    '鸡',
    {
      'chong': ['兔'], // 相冲
      'xing': ['鸡'], // 相刑，相同就是自刑
      'hai': ['狗'], // 相害
      'po': ['鼠'], // 相破
      'sanhe': ['蛇', '牛'], // 三合
      'liuhe': ['龙'], // 六合
    }
  ],
  [
    '狗',
    {
      'chong': ['龙'], // 相冲
      'xing': ['牛', '羊'], // 相刑
      'hai': ['鸡'], // 相害
      'po': ['狗'], // 相破
      'sanhe': ['马', '虎'], // 三合
      'liuhe': ['兔'], // 六合
    }
  ],
  [
    '猪',
    {
      'chong': ['蛇'], // 相冲
      'xing': ['猪'], // 相刑，相同就是自刑
      'hai': ['猴'], // 相害
      'po': ['虎'], // 相破
      'sanhe': ['兔', '羊'], // 三合
      'liuhe': ['虎'], // 六合
    }
  ],
]);

// 生肖相冲说明
export const shengXiaoChongDesc = new Map([
  [
    '相冲',
    '代表意见不合、容易有冲突、彼此相冲。同阴同阳之相克，为无情冲克，冲力大而激，逢之大凶；相冲谓冲入，代表拖拉、虚耗、事多阻碍、打击、压制、不顺、失败、反背、破坏、肃杀、凶险、六亲缘薄或受伤害、受连累、使身心不安之种种现象。',
  ],
  [
    '相刑',
    '代表个性不合、互有刑克。刑我者为积欠、为还、互相欠债。',
  ],
  [
    '自刑',
    '表示非常容易因事而各持己见，引起争斗与吵闹',
  ],
  [
    '相害',
    '代表意见不合、争吵、结怨、争斗、失败之象。为感情、金钱、精神之破失、伤身、伤财、又伤心。',
  ],
  [
    '相破',
    '互相破坏、破产、捣乱、矛盾冲突。',
  ],
  [
    '三合',
    '三合的组合，不但契合度佳，思想、价值观、习惯、动作...总认为是那么情投意合。三合生肖相差四年，正所谓差４岁结婚，才是天作之合，就是根据「三合」而来的。'
  ],
  [
    '六合',
    '六合是指暗中帮助你的贵人，又表示恩爱、情合、圆融、喜庆、财禄、合伙、扩增、发展、旺气、利益，为一分耕耘，一分收获。'
  ],
  [
    '三合六合',
    '合则为顺，合则为助，代表诸事顺畅、顺心，又为互助互惠；',
  ],
]);

export default memo(() => {
  const { ymdStr, lunarData, Chinese, language } = useContext(TungShingContext);
  const cnYmd = dayjs(ymdStr).format("YYYY年M月D日");
  const dayShengXiao = lunarData.shengxiao.dayShengXiao; // 今日生肖
  const sxchong = shengXiaoChong.get(dayShengXiao);
  const isZiXing = sxchong.xing.includes(dayShengXiao); // 是否自刑，相刑，相同就是自刑

  return (
    <div className="infoitem">
      <h3>{Chinese[language](`${cnYmd}黄历冲什么属相`)}</h3>
      <div className="body">
        <div className="wrap">
          <div className="title">
            {Chinese[language]('今日生肖属相是')}<span>{Chinese[language](dayShengXiao)}</span>
          </div>
          <div className="content">
            <p>
              {Chinese[language](`与生肖${sxchong.chong.join('、')}`)}<span>{Chinese[language]('相冲')} </span>
              <Tooltip title={Chinese[language](shengXiaoChongDesc.get('相冲'))}>
                <QuestionCircleOutlined />
              </Tooltip>
            </p>
            <p>
              {Chinese[language](`与生肖${sxchong.xing.join('、')}`)}<span>{Chinese[language](isZiXing ? '自刑' : '相刑')} </span>
              <Tooltip title={Chinese[language](isZiXing ? shengXiaoChongDesc.get('自刑') : shengXiaoChongDesc.get('相刑'))}>
                <QuestionCircleOutlined />
              </Tooltip>
            </p>
            <p>
              {Chinese[language](`与生肖${sxchong.hai.join('、')}`)}<span>{Chinese[language]('相害')} </span>
              <Tooltip title={Chinese[language](shengXiaoChongDesc.get('相害'))}>
                <QuestionCircleOutlined />
              </Tooltip>
            </p>
            <p>
              {Chinese[language](`与生肖${sxchong.po.join('、')}`)}<span>{Chinese[language]('相破')} </span>
              <Tooltip title={Chinese[language](shengXiaoChongDesc.get('相破'))}>
                <QuestionCircleOutlined />
              </Tooltip>
            </p>
            <p>
              {Chinese[language](`与生肖${sxchong.sanhe.join('、')}`)}<span>{Chinese[language]('三合')} </span>
              <Tooltip title={Chinese[language](shengXiaoChongDesc.get('三合'))}>
                <QuestionCircleOutlined />
              </Tooltip>
            </p>
            <p>
              {Chinese[language](`与生肖${sxchong.liuhe.join('、')}`)}<span>{Chinese[language]('六合')} </span>
              <Tooltip title={Chinese[language](shengXiaoChongDesc.get('六合'))}>
                <QuestionCircleOutlined />
              </Tooltip>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
});
